import React from "react";
import cx from "classnames";
import Player from "react-player/lazy";
import { useIntersectionObserver } from "usehooks-ts";

import CloseIcon from "@svg/close.svg";
import PlayIcon from "@svg/play.svg";

import { ModuleWrapper } from "../moduleWrapper";
import PortableTextBlock from "@components/sanity/portableTextBlock";
import PortalModal from "@components/ui/portalModal";
import RoundButton from "@components/ui/roundButton";

const VideoText = ({ config, text, layout, videoUrl }) => {
  const ref = React.useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <ModuleWrapper
      {...config}
      className="grid px-gutter  md:grid-cols-2 md:items-center md:gap-x-20"
      revealAnimation
    >
      {/* Image */}
      <div
        className={cx("row-start-2 md:row-start-1 relative col-span-full", {})}
        onClick={handleOpen}
        ref={ref}
      >
        <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center z-20 text-white bg-black bg-opacity-30 cursor-pointer rounded-lg">
          <RoundButton isLarge>
            <PlayIcon className="w-6 fill-current" />
          </RoundButton>
        </div>
        <div className="aspect-w-16 aspect-h-9 w-full relative z-10 rounded-lg overflow-hidden">
          {isVisible && (
            <Player
              url={videoUrl}
              playing
              playsinline
              muted
              controls={false}
              width="100%"
              height="100%"
              className="bg-video"
            />
          )}
        </div>
      </div>

      <PortalModal isShowing={isOpen}>
        <div className="absolute right-4 top-4 text-white z-50">
          <RoundButton onClick={handleClose}>
            <CloseIcon className="w-4 fill-current " />
          </RoundButton>
        </div>
        <div className="w-screen h-screen relative max-w-7xl mx-auto">
          <Player
            url={videoUrl}
            playing
            controls
            width="100%"
            height="100%"
            className="fit-video z-10"
            onEnded={handleClose}
          />
        </div>
      </PortalModal>
    </ModuleWrapper>
  );
};

export default VideoText;
