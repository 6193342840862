import React from "react";
import cx from "classnames";

const RoundButton = ({
  children,
  isLarge = false,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={cx(
        " w-16  h-16 rounded-full flex justify-center items-center relative overflow-hidden group",
        {
          "lg:w-20 lg:h-20": isLarge,
          "cursor-not-allowed opacity-50": disabled,
        }
      )}
      ariaLabel="next button"
      {...props}
    >
      {children}
      <span className="absolute rounded-full w-full h-full top-0 left-0 bg-current transition-opacity duration-500 opacity-20 group-hover:opacity-30" />
    </button>
  );
};

export default RoundButton;
